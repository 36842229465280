import AiIcon from "../../../../assets/svgs/AIIcon";
import AIGenerateButton from "../../../../Component/AIGenerateButton/AIGenerateButton";
import EmptyView from "../../../../Component/EmptyView/EmptyView";
import Spinner from "../../../../Component/Spinner/Spinner";
import { getAttDataWithDelta } from "../../../../utils/helpers";
import ReBarChart from "../../common/ReBarchart";

export default function NegativeAttributes({
  negativeAttGraphData,
  pastNegativeAttGraphData,
  loading,
  errors,
  summaries,
  getAttributesSummary,
}) {
  return (
    <div className="col-md-12 mt-4">
      <div className="card p-3 custCard">
        <div className="row">
          <div className="col-md-8">
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <h5 className="me-3">Negative Attributes</h5>
            </div>
            {negativeAttGraphData && negativeAttGraphData.length ? (
              <div>
                <ReBarChart
                  data={
                    getAttDataWithDelta(
                      negativeAttGraphData,
                      pastNegativeAttGraphData
                    ) || []
                  }
                  color="#FEE7E6"
                />
              </div>
            ) : (
              <EmptyView message="Data Not Available" />
            )}
          </div>
          <div className="col-md-4">
            <div className="graphGreyBg">
              <p className="mb-3 font14-600">
                Summary &nbsp; &nbsp;
                <AIGenerateButton
                  fetchSummary={getAttributesSummary}
                  type="negative-attributes"
                />
              </p>
              <div className="graphsummary font-11">
                {loading?.negativeAttributes ? (
                  <Spinner />
                ) : summaries?.negativeAttributes ? (
                  <p className="mb-1"> {summaries?.negativeAttributes} </p>
                ) : errors?.negativeAttributes ? (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {errors?.negativeAttributes}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
