import React, { useState } from "react";
import DvrIcon from "@mui/icons-material/Dvr";
import MUICard from "./../../../../Component/UI/MUICard/MUICard";
import classes from "./BrandScore.module.css";
import Header from "./../../../../Component/Header/Header";
import GaugeChart from "./../../../../Component/GaugeChart/GaugeChart";
import BrandInsights from "./BrandInsights/BrandInsights";
import LLMIndicator from "./../../../Discovery/LLMIndicator/LLMIndicator";
import ReLineChart from "../../common/ReLinechart";
import EmptyView from "../../../../Component/EmptyView/EmptyView";
import { extractDomain } from "../../../../utils/helpers";
import Spinner from "../../../../Component/Spinner/Spinner";
import RadialGraph from "../../common/ReRadialGraph";
import AiIcon from "../../../../assets/svgs/AIIcon";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../../../Component/Modal/Modal";
import BrandIndexBreakups from "../../../../Component/BrandIndexHistory/BrandIndexBreakups";
import MUIIconButton from "../../../../Component/UI/MUIIconButton/MUIIconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import { metricExplainations } from "../../../../utils/constant";

export default function BrandScore({
  indexScoreMetaData,
  category,
  type,
  indexScoreDataForLineChart,
  domain,
  brandProductCategory,
  brandProduct,
  brandProductInfo,
  errors,
  summaries,
  loading,
  brandIndexScore,
  pastBrandIndexScore,
  frequency,
}) {
  const [showBrandIndexBreakups, setShowBrandIndexBreakups] = useState(false);
  const getDelta = (currentScore, pastScore) => {
    if (currentScore && pastScore) {
      const delta =
        Number(Object.values(currentScore)).toFixed(0) -
        Number(Object.values(pastScore)).toFixed(0);
      return delta;
    }
  };

  const reachDelta = getDelta(brandIndexScore?.Rank, pastBrandIndexScore?.Rank);
  const sentimentDelta = getDelta(
    brandIndexScore?.Favorability,
    pastBrandIndexScore?.Favorability
  );

  const dateMapping = {
    Current: "Current",
    "3Days": "Last 3 Days",
    "7Days": "Last 7 Days",
    Month: "Last 30 Days",
    "3Month": "Last 90 Days",
  };
  return (
    <div className={classes.wrapper}>
      <MUICard variant="elevated" elevation={0} className={classes.card}>
        <div className={classes.container}>
          <div className={classes.brand_container}>
            <Header
              title={
                <div className={classes.title_container}>
                  {domain && (
                    <img
                      className={classes.logo}
                      src={`https://img.logo.dev/${extractDomain(
                        domain
                      )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                    />
                  )}
                  <div className="pt-2">
                    <span>{brandProduct}</span>

                    <div className={classes.label_container}>
                      <span className={classes.title_label}>
                        {brandProductCategory}
                      </span>
                    </div>
                  </div>
                </div>
              }
              titleClassName={classes.title}
              actionVariant="btn"
              actionText=""
              actionBtnVariant="outlined"
              actionIcon={<DvrIcon />}
              actionBtnClassName={`${classes.start_monitoring} no-print`}
              className={classes.header}
            />

            <div style={{ marginTop: "10px" }}>
              <BrandInsights
                brandProductInfo={brandProductInfo}
                type={type}
                category={category}
              />
            </div>
            <br />

            <div className={classes.brand_index_wrapper}>
              <div style={{ borderRight: "1px solid #d9d9d9" }}>
                {brandIndexScore?.["Brand Index Score"] ? (
                  <div className={classes.brand_index}>
                    <div className={classes.main}>
                      {indexScoreMetaData?.extras && (
                        <MUIIconButton
                          data-html2canvas-ignore
                          className={classes.info}
                          onClick={() => setShowBrandIndexBreakups(true)}
                        >
                          <InfoOutlinedIcon />
                        </MUIIconButton>
                      )}
                      <div>
                        <h5>
                          <Tooltip
                            title={
                              metricExplainations[
                                `${
                                  type === "Company/Brand" ? "Brand" : "Product"
                                } Index Score`
                              ]
                            }
                          >
                            {type === "Company/Brand" ? "Brand" : "Product"}{" "}
                            Index Score
                          </Tooltip>
                        </h5>

                        <div style={{ margin: "30px 10px 0px 0px" }}>
                          <RadialGraph
                            label={`Brand Index Score-${brandProductCategory}`}
                            value={brandIndexScore?.["Brand Index Score"]}
                            pastValue={
                              pastBrandIndexScore?.["Brand Index Score"]
                            }
                            Range={100}
                            frequencyLabel={dateMapping[frequency]}
                          />
                        </div>
                      </div>
                      <div className={classes.sub}>
                        <div className={classes.score}>
                          <div className={classes.score_content}>
                            <span className={classes.score_text}>
                              <Tooltip title={metricExplainations[`Reach`]}>
                                Reach
                              </Tooltip>
                            </span>
                            {brandIndexScore?.Rank && (
                              <div>
                                <span className={classes.score_value}>
                                  {Number(
                                    Object.values(brandIndexScore?.Rank)
                                  ).toFixed(0)}
                                </span>
                                {/* {reachDelta !== undefined && (
                                <>
                                  &nbsp; &nbsp;&nbsp;
                                  <span
                                    style={{
                                      color: "grey",
                                      marginLeft: "-10px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className={
                                        reachDelta < 0
                                          ? `${classes["core-down-icon"]} text-danger me-1`
                                          : `${classes["core-up-icon"]} text-success me-1`
                                      }
                                      icon={
                                        reachDelta < 0 ? faSortDown : faSortUp
                                      }
                                    />
                                  </span>
                                  <span
                                    style={{ color: "grey", marginLeft: "1px" }}
                                    className={classes.coreDelta}
                                  >
                                    {Number(Math.abs(reachDelta)).toFixed(0)}
                                  </span>
                                </>
                              )} */}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={classes.partition}></div>
                        <div className={classes.score}>
                          <div className={classes.score_content}>
                            <span className={classes.score_text}>
                              <Tooltip title={metricExplainations[`Sentiment`]}>
                                Sentiment
                              </Tooltip>
                            </span>

                            {brandIndexScore?.Favorability && (
                              <div>
                                <span className={classes.score_value}>
                                  {Number(
                                    Object.values(brandIndexScore?.Favorability)
                                  ).toFixed(0)}
                                </span>
                                {/* {sentimentDelta != undefined && (
                                <>
                                  &nbsp; &nbsp;&nbsp;
                                  <span
                                    style={{
                                      color: "grey",
                                      marginLeft: "-10px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      className={
                                        sentimentDelta < 0
                                          ? `${classes["core-down-icon"]} text-danger me-1`
                                          : `${classes["core-up-icon"]} text-success me-1`
                                      }
                                      icon={
                                        sentimentDelta < 0
                                          ? faSortDown
                                          : faSortUp
                                      }
                                    />
                                  </span>
                                  <span
                                    style={{ color: "grey", marginLeft: "1px" }}
                                    className={classes.coreDelta}
                                  >
                                    {Number(Math.abs(sentimentDelta)).toFixed(
                                      0
                                    )}
                                  </span>
                                </>
                              )} */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="graphsummary2">
                        <p className="mb-2">
                          Summary
                          <button className="mb-1 ms-2 btn-purple text-white">
                            <AiIcon />
                            AI Generated
                          </button>
                        </p>

                        {loading?.indexScore ? (
                          <Spinner />
                        ) : summaries?.indexScore ? (
                          <p className="font-10 darker-grey summaryList2">
                            {summaries?.indexScore}
                          </p>
                        ) : errors?.indexScore ? (
                          <p style={{ color: "red", textAlign: "center" }}>
                            {errors?.indexScore}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {/* <h5> Data Not Available</h5> */}
                    <h5>
                      {/* {type === "Company/Brand" ? "Brand" : "Product"} Index Score */}
                      <Tooltip
                        title={
                          metricExplainations[
                            `${
                              type === "Company/Brand" ? "Brand" : "Product"
                            } Index Score`
                          ]
                        }
                      >
                        {type === "Company/Brand" ? "Brand" : "Product"} Index
                        Score
                      </Tooltip>
                      <EmptyView message="Data Not Available" />
                    </h5>
                  </>
                )}
              </div>

              <div className={classes.summary}>
                {indexScoreDataForLineChart &&
                indexScoreDataForLineChart.length ? (
                  <ReLineChart data={indexScoreDataForLineChart} />
                ) : (
                  <span className={classes.brand_label}>
                    <EmptyView message="Competitor Data Not Available" />
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={showBrandIndexBreakups}
          handleClose={() => setShowBrandIndexBreakups(false)}
          width={1200}
        >
          <BrandIndexBreakups data={indexScoreMetaData?.extras} />
        </Modal>
      </MUICard>
    </div>
  );
}
