import { useContext, useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { CircularProgress } from "@mui/material";

import TextInput from "./../../Component/UI/TextInput/TextInput";
import MUIButton from "../../Component/UI/MUIButton/MUIButton";
import RadioGroup from "../../Component/UI/RadioGroup/RadioGroup";
import MUISelect from "../../Component/UI/MUISelect/MUISelect";
import classes from "./Form.module.css";
import { DiscoveryContext } from "./discovery-context";
import { LLM_OPTIONS } from "../../utils/constant";
import { validateBrandInput } from "../../utils/helpers";

const RADIO_OPTIONS = [
  {
    value: "brand",
    label: "Brand",
  },
  {
    value: "product",
    label: "Product",
  },
  /*{
    value: "market",
    label: "Market Research",
  },*/
];

export default function Form({ onSubmit, disabled, onTypeChanged = () => {} }) {
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [market, setMarket] = useState("");
  const [llm, setLLM] = useState("gpt-4o");
  const [type, setType] = useState("brand");

  const [loading, setLoading] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);

  const discoveryCtx = useContext(DiscoveryContext);

  async function checkIfInputValid(input) {
    setLoading(true);
    try {
      let response = await validateBrandInput(input);
      setLoading(false);
      if (response.data === "Yes") {
        setIsInputValid(true);
      } else {
        setIsInputValid(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  /*useEffect(() => {
    if (!brand) return;
    let timer = setTimeout(() => {
      checkIfInputValid(brand);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [brand]);*/

  return (
    <div className={classes.form}>
      <RadioGroup
        options={RADIO_OPTIONS}
        onChange={(value) => {
          setType(value);
          onTypeChanged(value);
        }}
      />
      <div className={classes.input_group}>
        <div className={classes.brand_input}>
          <TextInput
            fullWidth
            label={type === "brand" ? "Brand*" : "Product*"}
            placeholder={type === "brand" ? "Nike" : "Nike Air Jordan 1"}
            onChange={(e) => setBrand(e.target.value)}
          />
          {loading && (
            <CircularProgress className={classes.loading} size={15} />
          )}
        </div>

        <TextInput
          label="Category*"
          placeholder="Athletic Apparel"
          onChange={(e) => setCategory(e.target.value)}
        />
        <TextInput
          label="Market"
          placeholder="North America"
          onChange={(e) => setMarket(e.target.value)}
        />
        <MUISelect
          label={"LLM*"}
          defaultValue="gpt-4o"
          options={LLM_OPTIONS}
          onChange={(e) => setLLM(e.target.value)}
        />
        <MUIButton
          variant="contained"
          color={discoveryCtx.loading ? "error" : "primary"}
          onClick={() => {
            if (!brand || !category || !llm) {
              toast.warn("Please fill all required fields");
              return;
            }

            if (discoveryCtx.loading) {
              discoveryCtx.updateAbortLaunch(true);
              return;
            }

            onSubmit({
              brand,
              category,
              market,
              llm,
              type,
            });
          }}
        >
          {discoveryCtx.loading ? "Cancel" : "Launch"}
        </MUIButton>
      </div>
      {/* {!isInputValid && brand != "" && !loading && (
        <span className={classes.error}>Please enter valid input</span>
      )} */}
      <ToastContainer />
    </div>
  );
}
