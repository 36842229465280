import MUIButton from "../UI/MUIButton/MUIButton";
import classes from "./AIGenerateButton.module.css";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

const AIGenerateButton = ({ fetchSummary, type }) => {
  return (
    <MUIButton
      onClick={() => {
        fetchSummary(type);
      }}
      variant="outlined"
      className={classes.regenerate}
      startIcon={<AutoAwesomeOutlinedIcon />}
    >
      Generate
    </MUIButton>
  );
};

export default AIGenerateButton;
