import { useState } from "react";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import SentimentBar from "./SentimentBar";
import classes from "./SentimentItem.module.css";
import MUIChip from "../UI/MUIChip/MUIChip";

function SentimentItem({ sentiment }) {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  function handleItemSelect(index) {
    setActiveItemIndex(index);
  }

  const selectedSentiment = sentiment[activeItemIndex];

  let sentimentIcon = "";
  switch (selectedSentiment?.llm_sentiment) {
    case "Positive":
      sentimentIcon = (
        <SentimentVerySatisfiedIcon style={{ color: "#45a456" }} />
      );
      break;
    case "Slightly Positive":
      sentimentIcon = (
        <SentimentSatisfiedAltIcon style={{ color: "#45a456" }} />
      );
      break;
    case "Neutral":
      sentimentIcon = <SentimentNeutralIcon style={{ color: "#fb9d1c" }} />;
      break;
    case "Slightly Negative":
      sentimentIcon = (
        <SentimentDissatisfiedIcon style={{ color: "#e2474d" }} />
      );
      break;
    case "Negative":
      sentimentIcon = (
        <SentimentVeryDissatisfiedIcon style={{ color: "#e2474d" }} />
      );
      break;
    default:
      sentimentIcon = ""
  }

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        {sentiment.length > 1 && sentiment.map((_, index) => {
          return (
            <MUIChip
              key={index}
              label={index + 1}
              className={
                index === activeItemIndex ? classes.active : classes.inactive
              }
              onClick={() => {
                handleItemSelect(index);
              }}
            ></MUIChip>
          );
        })}
      </div>
      <div>
        { !selectedSentiment?.confidenceScores && <div className="d-flex flex-column my-3">
          <span className={classes.sentiment_text}>
            {selectedSentiment?.llm_sentiment}
          </span>
          <div className="d-flex align-items-center">
            { sentimentIcon && <span className={classes.sentiment_icon}>{sentimentIcon}</span> }
            <span className={classes.sentiment_score}>
              {(selectedSentiment?.sentiment != undefined || selectedSentiment?.sentiment != null) ? `Sentiment: ${selectedSentiment?.sentiment}`: selectedSentiment?.llm_sentiment_score}
            </span>
          </div>
        </div> }
        {selectedSentiment?.confidenceScores && selectedSentiment?.llm_sentiment && (
          <SentimentBar
            confidenceScores={selectedSentiment?.confidenceScores}
            sentiment={selectedSentiment?.llm_sentiment}
          />
        )}
        {selectedSentiment?.question && (
          <b className="d-block">{selectedSentiment?.question}</b>
        )}
        <p className={classes.response_text}>{selectedSentiment.response}</p>

        {selectedSentiment?.explanation && (
          <div>
            <b>Explanation:</b>
            <br />
            <span className={classes.response_text}>
              {selectedSentiment?.explanation}
            </span>
          </div>
        )}
        <hr />
      </div>
    </div>
  );
}

export default SentimentItem;
