import React, { useState, useContext, useEffect } from "react";
import DateRangePicker from "../DateRangePicker";
import { AuthContext } from "../../../../context/auth-context";
import { getActionMetricsData } from "../../../../services/apiService";
import { ActionTable } from "./ActionTable";
import { Spinner } from "react-bootstrap";
import EmptyView from "../../../../Component/EmptyView/EmptyView";

const Actions = () => {
  const { user } = useContext(AuthContext);

  const customerId = user?.customer_id || 0;

  const [selectedSubFrequencyItem, setSelectedSubFrequencyItem] =
    useState("Current");

  const [reachActionsData, setReachActionsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [riskActionsData, setRiskActionsData] = useState([]);
  const [oppActionsData, setOppActionsData] = useState([]);

  const handlesubFrequencyCheckChange = (key) => {
    setSelectedSubFrequencyItem(key);
  };

  useEffect(() => {
    if (selectedSubFrequencyItem) {
      fetchActionMetricsData();
    }
  }, [selectedSubFrequencyItem]);

  const processActionsData = (data, type) => {
    const brandPillarsWithKey = data?.brandPillarActions?.[type]?.map(
      (obj) => ({
        ...obj,
        metricType: "Brand Pillar",
      })
    );

    const purchaseCriteriasWithKey = data?.purchaseCriteriaActions?.[type]?.map(
      (obj) => ({
        ...obj,
        metricType: "Purchase Criteria",
      })
    );
    return [...brandPillarsWithKey, ...purchaseCriteriasWithKey];
  };

  const fetchActionMetricsData = async () => {
    const payload = {
      customerId,
      timePeriod: selectedSubFrequencyItem,
    };
    try {
      setIsLoading(true);
      const data = await getActionMetricsData(payload);
      const riskData = processActionsData(data, "risk");
      const oppData = processActionsData(data, "opp");
      setRiskActionsData(riskData);
      setOppActionsData(oppData);
      setReachActionsData(data?.reachActionsDataWithSources);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <div
          style={{ marginBottom: "-15px" }}
          class="row justify-content-between"
        >
          <DateRangePicker
            activeTarget={selectedSubFrequencyItem}
            handleChange={handlesubFrequencyCheckChange}
          />
        </div>

        <div class="col-md-12 mt-4">
          {isLoading ? (
            <div
              style={{
                fontSize: "19px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
                // color: "green",
              }}
            >
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              &nbsp; Loading...
            </div>
          ) : (
            <div class="col-md-12 mt-4">
              <div class="card p-3 custCard">
                <h5> Reach </h5>
                {!reachActionsData || reachActionsData?.length == 0 ? (
                  <EmptyView message="Nothing here" />
                ) : (
                  <ActionTable
                    tableType="reach"
                    actionMetricsData={reachActionsData}
                  />
                )}
              </div>
              <p> </p>
              <div class="card p-3 custCard">
                <h5> Risk </h5>

                {!riskActionsData || riskActionsData?.length == 0 ? (
                  <EmptyView message="Nothing here" />
                ) : (
                  <ActionTable
                    tableType="risk"
                    actionMetricsData={riskActionsData}
                  />
                )}
              </div>
              <p> </p>
              <div class="card p-3 custCard">
                <h5> Opportunity </h5>

                {!oppActionsData || oppActionsData?.length == 0 ? (
                  <EmptyView message="Nothing here" />
                ) : (
                  <ActionTable
                    tableType="opp"
                    actionMetricsData={oppActionsData}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Actions;
