import React from "react";
import Select, { components } from "react-select";

// Custom option component to include checkboxes
const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      {children}
    </components.Option>
  );
};

const CustomSelectWithApply = ({ options, selectedOptions, handleChange }) => {
  // Convert strings array to objects for react-select
  const optionsFormatted = options.map((option) => ({
    value: option,
    label: option,
  }));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      //   border: "none", // Remove the border
      boxShadow: "none", // Remove box shadow
      padding: "0", // Remove padding in control
      minHeight: "40px", // Set a fixed height
      maxHeight: "24px", // Set a max height to avoid expanding
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 8px", // Adjust padding inside the value container
      margin: "0", // Remove any margin that might affect spacing
      height: "24px", // Set a fixed height
      overflowY: "auto", // Enable vertical scrollbar if items overflow
      display: "flex",
      flexWrap: "nowrap", // Prevent wrapping of selected values
    }),
    input: (provided) => ({
      ...provided,
      margin: "0", // Remove any margin
      padding: "0", // Remove any padding
    }),
    multiValue: (provided) => ({
      ...provided,
      marginTop: "0px", // Adjust margin to move selected value up
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "0", // Adjust padding for selected value labels
      marginTop: "0px",
    }),
    indicatorSeparator: () => ({
      display: "none", // Remove the separator between the dropdown indicator and the value
      marginTop: "0px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0", // Remove padding for the dropdown indicator
      marginTop: "0px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: "0",
      marginTop: "0px", // Move the clear icon up
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "transparent"
        : provided.backgroundColor,
      color: "black",
      ":hover": {
        backgroundColor: "#f0f0f0",
      },
    }),
  };

  return (
    <div style={{ width: "60%" }}>
      <Select
        styles={customStyles}
        options={optionsFormatted}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          // Menu: DropdownWithApply
        }}
        onChange={handleChange}
        value={selectedOptions.map((option) => ({
          label: option,
          value: option,
        }))}
        placeholder="Select Competitors"
      />
    </div>
  );
};

export default CustomSelectWithApply;
