import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import EmptyView from "../../../Component/EmptyView/EmptyView";

// Persistent color mapping outside the component to maintain color consistency
const colorMapping = {};

// Define a set of default colors to use
const defaultColors = [
  "#C7F0EC",
  "#FF6F6F",
  "#CEB4FF",
  "#FFCE6E",
  "#92B7FE",
  "#FEE7E6",
  "#CEB4FF",
  "#FFCE6E",
];

// Function to assign colors to keys if not already assigned
const getColorForKey = (key, index) => {
  if (!colorMapping[key]) {
    colorMapping[key] =
      defaultColors[index % defaultColors.length] ||
      `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }
  return colorMapping[key];
};

const ReMultibarChart = ({ data, index }) => {
  // Use useMemo to compute the ordered list of keys once when the component mounts
  const orderedKeys = useMemo(() => {
    // Get all unique keys from the data array except for "name"
    const allKeys = Array.from(
      new Set(
        data?.flatMap((item) =>
          Object.keys(item).filter((key) => key !== "name")
        )
      )
    );
    return allKeys.sort(); // Sort keys alphabetically or based on custom order if needed
  }, [data]);

  // Filter and preprocess data to remove null/undefined values, avoiding empty spaces in bars
  const filteredData = useMemo(() => {
    return data?.map((item) => {
      const newItem = { ...item };
      orderedKeys.forEach((key) => {
        if (newItem[key] === null || newItem[key] === undefined) {
          newItem[key] = 0; // Replace null/undefined with 0 for consistency in display
        }
      });
      return newItem;
    });
  }, [data, orderedKeys]);

  const compLabelsMapping = {
    0: "Positive Attributes",
    1: "Negative Attributes",
  };

  const barSize = 30;
  const margin = { top: 20, right: 30, left: 20, bottom: 5 };
  const chartWidth = orderedKeys.length * barSize + margin.left + margin.right;

  return (
    <>
      <h5 className="me-3">{compLabelsMapping[index]}</h5>
      {filteredData && filteredData.length ? (
        <>
          <ResponsiveContainer
            width="100%"
            height={450}
            style={{ backgroundColor: "white" }}
          >
            <BarChart
              data={filteredData}
              width={chartWidth}
              height={300}
              margin={margin}
              barCategoryGap="30%"
            >
              <Legend
                verticalAlign="top"
                align="center"
                wrapperStyle={{ paddingBottom: 20 }}
              />
              <XAxis
                dataKey="name"
                tickLine={false}
                tick={{
                  fill: "#b6b7b8",
                  fontFamily: "Roboto Flex, sans-serif",
                  fontSize: 14,
                  width: 80,
                  wordWrap: "break-word",
                }}
                interval={0}
                height={60}
              />
              <YAxis
                tickLine={false}
                tick={{
                  fill: "#b6b7b8",
                  fontFamily: "Roboto Flex, sans-serif",
                  fontSize: 14,
                }}
                axisLine={{ stroke: "white" }}
                padding={{ top: 20, bottom: 10 }}
                domain={[0, 100]}
                tickCount={11}
                label={{
                  value: "Signal Strength (%)",
                  angle: -90, // Rotates the label to be vertical
                  position: "insideLeft", // Adjust position relative to the axis
                  fill: "#b6b7b8", // Label text color
                  fontSize: 14, // Label text size
                  fontFamily: "Roboto Flex, sans-serif", // Label font style
                  dy: 50, // Adjust vertical spacing
                }}
              />
              <Tooltip />

              {orderedKeys?.map((brand, index) => (
                <Bar
                  key={brand}
                  dataKey={brand}
                  fill={getColorForKey(brand, index)}
                  barSize={barSize}
                  isAnimationActive={true}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : (
        <EmptyView message="Data Not Available" />
      )}
    </>
  );
};

export default ReMultibarChart;
