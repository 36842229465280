import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, DialogActions } from "@mui/material";

import classes from "./Modal.module.css";

const Modal = ({
  open,
  handleClose,
  children,
  title,
  showActions = false,
  confirmBtnText,
  width,
  handleConfirm,
  confirmBtnColor = "primary",
  height = "100%",
  actionsClassName = ""
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      sx={{
        height: height
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: `${width}px!important`,
        },
      }}
    >
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
      {showActions && (
        <DialogActions className={actionsClassName}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color={confirmBtnColor}
            variant="contained"
          >
            {confirmBtnText || "Ok"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;