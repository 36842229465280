import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";

const BAR_HEIGHT = 65; // Height per bar
const MIN_HEIGHT = 300; // Minimum height for the chart
const FIXED_BAR_SIZE = 40; // Fixed bar width
const BAR_SPACING = 30; // Fixed space between bars

const CustomBarChart = ({ data, color }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const CustomTooltip = ({ payload, label }) => {
    if (payload && payload.length) {
      const { name, altNames, value } = payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: "#1F1A5F",
            padding: "10px",
            borderRadius: "15px",
            color: "#fff",
          }}
        >
          <p>
            <strong>Category : {name || ""}</strong> <br />
            <strong>Signal Strength : {value || ""}</strong>
          </p>
          <p>Attributes:</p>
          <ul>
            {altNames.map((altName, index) => (
              <li key={index}>{altName}</li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

  const handleMouseEnter = (_, index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  // Sort data by 'value' in descending order
  const sortedData = data?.slice().sort((a, b) => b.value - a.value);

  // Calculate the dynamic height
  const chartHeight = Math.max(MIN_HEIGHT, sortedData?.length * BAR_HEIGHT);

  // Custom Label Renderer with Icon
  const renderLabelWithIcon = (props) => {
    const { x, y, value, height, width } = props; // Access the height of the bar
    const delta = value !== null ? Number(value) : 0;

    const adjustedX = width + 8; // Adjust icon position based on bar width

    // Conditionally render up or down arrow based on delta value
    return (
      <g transform={`translate(${x}, ${y})`}>
        <foreignObject x={adjustedX} y={10} width={20} height={height}>
          <FontAwesomeIcon
            style={{ fontSize: "14px", marginBottom: delta < 0 ? "11px" : "0" }}
            className={`${delta < 0 ? "text-danger" : "text-success"}`}
            icon={delta < 0 ? faSortDown : faSortUp}
          />
        </foreignObject>
        <text
          dx={adjustedX + 12} // Adjust label position relative to the icon
          dy={24} // Center the label vertically within the bar using the height
          fill={delta < 0 ? "red" : "green"} // Color based on delta
          fontSize={14}
          fontFamily="Roboto Flex, sans-serif"
        >
          {delta ? Math.abs(delta) : "NA"}
        </text>
      </g>
    );
  };

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height={chartHeight}
        style={{ backgroundColor: "white" }}
      >
        <BarChart
          layout="vertical" // Switches the axes
          width={700}
          height={chartHeight}
          data={sortedData} // Use sorted data
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 30,
          }}
          barGap={BAR_SPACING} // Set space between bars
          barCategoryGap="10%" // Adjust category gap
        >
          <XAxis
            type="number" // X-axis now represents the values
            domain={[0, 100]} // Set limit from 0 to 100
            tickCount={11} // Number of ticks including 0
            tickLine={false}
            tick={{
              fill: "#b6b7b8",
              fontFamily: "Roboto Flex, sans-serif",
              fontSize: 14,
            }}
            axisLine={{ stroke: "#b6b7b8", strokeWidth: 1 }}
            // label={{
            //   value: "Signal Strength (%)", // The label text
            //   position: "insideBottom", // Positions the label below the ticks
            //   offset: -15, // Adjusts spacing between the label and ticks
            //   fill: "#b6b7b8", // Label text color
            //   fontSize: 14, // Label text size
            //   fontFamily: "Roboto Flex, sans-serif", // Label font style
            // }}
          />
          <YAxis
            dataKey="name" // Y-axis now represents the names
            type="category"
            tickLine={false}
            tick={{
              fontFamily: "Roboto Flex, sans-serif",
              fontSize: 14,
            }}
            width={150} // Increase the width to allow for wrapping
            axisLine={{ stroke: "white" }}
            padding={{ top: 10, bottom: 10 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="value"
            fill={color}
            radius={[10, 10, 10, 10]}
            barSize={FIXED_BAR_SIZE} // Set fixed bar width
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* <LabelList
            dataKey="delta"
            fill="red"
            content={renderLabelWithIcon} // Use the custom label renderer with icon
            style={{
              fontFamily: "Roboto Flex, sans-serif",
              fontSize: 14,
            }}
          /> */}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      <p style={{ textAlign: "center" }}> Signal Strength (%) </p>
    </>
  );
};

export default CustomBarChart;
