import { useState, useRef } from "react";
import Zoom from "@mui/material/Zoom";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";

import api from "../../../BrandOnBoarding/api";
import { IconButton, Skeleton } from "@mui/material";

let initial = false;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

export default function MUIToolTip({
  children,
  showSources,
  title = "",
  ...props
}) {
  const [loading, setLoading] = useState(true);
  const [sources, setSources] = useState([]);
  const [pinned, setPinned] = useState(false);
  const abortCtrlRef = useRef();

  abortCtrlRef.current = new AbortController();

  async function handleTooltipOpen() {
    try {
      setLoading(true);
      const payload = {
        text_content: title,
      };
      const response = await api.post("/metrics/sources", payload, {
        signal: abortCtrlRef.current.signal,
      });
      setSources(response.data.citations);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function LoadingSkeleton() {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", padding: "10px 0" }}
      >
        <Skeleton
          width={"80%"}
          className="mb-2"
          height={5}
          variant="rectangular"
          style={{ color: "red" }}
        />
        <Skeleton
          width={"80%"}
          className="mb-2"
          height={5}
          variant="rectangular"
          style={{ color: "red" }}
        />
        <Skeleton
          width={"80%"}
          height={5}
          variant="rectangular"
          style={{ color: "red" }}
        />
      </div>
    );
  }

  function handleTooltipClose() {
    if (abortCtrlRef.current) {
      abortCtrlRef.current.abort();
    }
  }

  function ToolTipContent() {
    return (
      <div style={{ position: "relative" }}>
        <p>{title}</p>
        <span>Sources</span>
        {/* <IconButton
          onClick={() => {
            setPinned((value) => !value);
            initial = false;
          }}
          style={{ position: "absolute", bottom: 2, right: 2 }}
        >
          <PushPinIcon />
        </IconButton> */}
        {loading && <LoadingSkeleton />}
        {!loading && (
          <ul style={{ padding: 0 }}>
            {sources.map((source) => {
              return (
                <li style={{ listStyle: "none" }} key={source.source}>
                  <a target="_blank" href={source.source}>
                    {source.source}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }

  return (
    <LightTooltip
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      slots={{
        transition: Zoom,
      }}
    //   open={ !initial ? false : pinned}
      title={showSources ? <ToolTipContent /> : title}
      {...props}
    >
      {children}
    </LightTooltip>
  );
}
