import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const sortedPayload = payload?.sort((a, b) => {
      return b["value"] - a["value"];
    });
    return (
      <div className="custom-tooltip-container">
        <ul style={{ paddingLeft: 10, margin: 0 }}>
          {sortedPayload?.map((obj, index) => {
            return (
              <li key={index}>
                {obj?.name} : {obj?.value}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return null;
};

const ReLineChart = ({ data }) => {
  const colors = [
    "#8884d8",
    "#82ca9d",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A28ADF",
    "#F44E54",
    "#2A9D8F",
    "#E76F51",
    "#264653",
  ];

  const brands =
    data && data.length !== 0
      ? [
          ...new Set(
            data.flatMap((obj) =>
              Object.keys(obj).filter((key) => key !== "date")
            )
          ),
        ]
      : [];

  return (
    <div style={{ height: "380px", width: "100%" }}>
      {/* <h5>Time-Series Chart</h5> */}
      <ResponsiveContainer
        width="100%"
        height="100%"
        style={{ backgroundColor: "white" }}
      >
        <LineChart data={data} margin={{ right: 5 }}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="date"
            axisLine={{ stroke: "white" }}
            tickLine={false}
            tickMargin={0}
            // label={{
            //   value: "Time-Series Chart", // The label text
            //   position: "insideBottom", // Positions the label below the ticks
            //   offset: -1, // Adjusts spacing between the label and ticks
            //   // fill: "#b6b7b8", // Label text color
            //   fontSize: 14, // Label text size
            //   fontFamily: "Roboto Flex, sans-serif", // Label font style
            // }}
          />
          <YAxis
            domain={[0, 100]} // Set the domain from 0 to 100
            tickCount={11} // Number of ticks (0, 10, 20, ... 100)
            axisLine={{ stroke: "white" }}
            tickLine={false}
            tickMargin={12}
            label={{
              value: "Brand Index Score",
              angle: -90, // Rotates the label to be vertical
              position: "insideLeft", // Adjust position relative to the axis
              // fill: "black", // Label text color
              fontSize: 14, // Label text size
              fontFamily: "Roboto Flex, sans-serif", // Label font style
              dy: 50, // Adjust vertical spacing
            }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ paddingBottom: 20 }} verticalAlign="top" />
          {brands &&
            brands.length > 0 &&
            brands?.map((brand, index) => (
              <Line
                key={brand}
                type="monotone"
                dataKey={brand}
                stroke={colors[index % colors.length]}
                fill={colors[index % colors.length]}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ReLineChart;
