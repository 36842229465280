import React, { useState, useEffect } from "react";

import {
  generateSourceDashboardJsonPrompt,
  transformDataForMultibarChart,
} from "../../../utils/helpers";
import ReVerticalMultibarChart from "../common/ReVerticalMultibarChart";
import { getDashboardSummary } from "../../../services/apiService";
import AiIcon from "../../../assets/svgs/AIIcon";
import { Spinner } from "react-bootstrap";
import Markdown from "markdown-to-jsx";
import MUIButton from "./../../../Component/UI/MUIButton/MUIButton";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import classes from "./DashboardComponent.module.css";

const SourceComponent = ({
  positiveAttSource,
  negativeAttSource,
  categoryDimensionSource,
  categoryDimensionRatingSource,
  coreAttSourceData,
  reachSourceData,
  sentimentSourceData,
  brandProduct,
  isProductDashboard,
}) => {
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // useEffect(() => {
  //   fetchSummary();
  // }, []);

  const positiveAttSourceBarChartData =
    transformDataForMultibarChart(positiveAttSource);
  const negativeAttSourceBarChartData =
    transformDataForMultibarChart(negativeAttSource);

  const categoryDimSourceBarChartData = transformDataForMultibarChart(
    categoryDimensionSource
  );
  const categoryDimRatingSourceBarChartData = transformDataForMultibarChart(
    categoryDimensionRatingSource
  );

  const coreAttSourceBarChartData =
    transformDataForMultibarChart(coreAttSourceData);
  const reachSourceBarChartData =
    transformDataForMultibarChart(reachSourceData);
  const sentimentSourceBarChartData =
    transformDataForMultibarChart(sentimentSourceData);

  const aggregatedBrandSources = mergeMultipleArrays(
    sentimentSourceBarChartData,
    positiveAttSourceBarChartData,
    negativeAttSourceBarChartData
  );

  const aggregatedCategorySources = mergeMultipleArrays(
    reachSourceBarChartData,
    categoryDimSourceBarChartData,
    categoryDimRatingSourceBarChartData,
    coreAttSourceBarChartData
  );

  const brandSourcesChartDataArray = sortArraysByLLMFrequency([
    aggregatedBrandSources,
    sentimentSourceBarChartData,
    positiveAttSourceBarChartData,
    negativeAttSourceBarChartData,
  ]);

  const categorySourcesChartDataArray = sortArraysByLLMFrequency([
    aggregatedCategorySources,
    reachSourceBarChartData,
    categoryDimSourceBarChartData,
    categoryDimRatingSourceBarChartData,
    coreAttSourceBarChartData,
  ]);

  const getTruncatedText = () => {
    const words = summary?.split(" ");
    return words.slice(0, 30).join(" ") + (words.length > 50 ? "...." : "");
  };

  const fetchSummary = async () => {
    setIsLoading(true);
    try {
      const data = [brandSourcesChartDataArray, categorySourcesChartDataArray];
      const jsonData = generateSourceDashboardJsonPrompt(JSON.stringify(data));
      const payload = {
        entity: brandProduct,
        dashboardType: "sourceDashboard",
        jsonData,
      };
      const response = await getDashboardSummary(payload);
      setSummary(response);
    } catch (error) {
      console.error("Error fetching summary:", error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("sources==>", JSON.stringify(positiveAttSourceBarChartData));

  function sortArraysByLLMFrequency(arrays) {
    return arrays?.map((array) => {
      // Dynamically determine the list of unique LLM keys in the current array
      const llmKeys = Array.from(new Set(array?.flatMap(Object.keys))).filter(
        (key) => key !== "name"
      ); // Exclude 'name' or any non-LLM key if necessary

      // Sort the array based on the count of LLM keys present in each object
      return array?.sort((a, b) => {
        const countLLMKeys = (obj) =>
          llmKeys.filter((key) => key in obj).length;
        return countLLMKeys(b) - countLLMKeys(a); // Sort in descending order
      });
    });
  }

  function mergeMultipleArrays(...arrays) {
    const merged = {};

    arrays.flat().forEach((item) => {
      // Check if the item is defined and has a 'name' property
      if (item && typeof item.name === "string") {
        const { name, ...llms } = item;

        // If the name is not yet in the merged object, add it
        if (!merged[name]) {
          merged[name] = llms;
        } else {
          // Count the number of LLMs for the existing entry and the current item
          const existingLLMsCount = Object.keys(merged[name]).length;
          const newLLMsCount = Object.keys(llms).length;

          // If the current item has more LLMs, replace the existing one
          if (newLLMsCount > existingLLMsCount) {
            merged[name] = llms;
          }
        }
      }
    });

    // Convert the merged object back to an array
    return Object.entries(merged).map(([name, llms]) => ({
      name,
      ...llms,
    }));
  }

  return (
    <>
      {/* <div className="col-md-12">
        <div className="graphGreyBg">
          <p style={{ display: "flex", justifyContent: "center" }}>
            <h4>Headlines & Focus Areas</h4>
            &nbsp; &nbsp;
            <MUIButton
              onClick={fetchSummary}
              variant="outlined"
              className={classes.regenerate}
              startIcon={<AutoAwesomeOutlinedIcon />}
            >
              Generate
            </MUIButton>
          </p>
          <div className="graphsummary font-11">
            {isLoading ? (
              <div
                style={{
                  fontSize: "19px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  // color: "green",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp; Loading...
              </div>
            ) : summary && typeof summary === "string" ? (
              <>
                <Markdown className="markTable">
                  {isExpanded ? summary : getTruncatedText()}
                </Markdown>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? "Show Less" : "Show More"}
                </span>
              </>
            ) : (
              // news
              ""
            )}
          </div>
        </div>
      </div> */}
      <h4 style={{ textAlign: "center", margin: "10px 0px -10px 0px" }}>
        {isProductDashboard ? "Product" : "Brand"} Query Sources
      </h4>
      {brandSourcesChartDataArray
        // ?.filter((item) => item.length)
        ?.map((data, index) => {
          return (
            <div className="col-md-12 mt-4">
              <div className="card p-3 custCard">
                <div className="mb-3 d-flex justify-content-between align-items-center"></div>
                <div>
                  <ReVerticalMultibarChart
                    isBrandSources={true}
                    index={index}
                    data={data}
                  />
                </div>
              </div>
            </div>
          );
        })}
      <hr />
      <h4 style={{ textAlign: "center", margin: "10px 0px -10px 0px" }}>
        Category Query Sources
      </h4>
      {categorySourcesChartDataArray
        // ?.filter((item) => item.length)
        ?.map((data, index) => {
          return (
            <div className="col-md-12 mt-4">
              <div className="card p-3 custCard">
                <div className="mb-3 d-flex justify-content-between align-items-center"></div>
                <div>
                  <ReVerticalMultibarChart
                    isBrandSources={false}
                    index={index}
                    data={data}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default SourceComponent;
