import classes from "./Actions.module.css";

const reachHeaders = [
  "Brand",
  "Reach",
  "LLM",
  "Persona",
  "Geography",
  "Market",
  "sources",
];

const nonReachHeaders = [
  "Brand",
  "Metric",
  "Score",
  "LLM",
  "Persona",
  "Geography",
  "Market",
  "sources",
];

export const ActionTable = ({ actionMetricsData, tableType }) => {
  const headers = tableType == "reach" ? reachHeaders : nonReachHeaders;
  return (
    <div className="col-md-12">
      <div className={classes.tableContainer}>
        <table className="table table-striped">
          <thead>
            <tr className="prdtblHeader">
              {headers.map((header) => (
                <th style={{ textAlign: "center" }}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {actionMetricsData &&
              actionMetricsData.length &&
              actionMetricsData?.map((data) => {
                return (
                  <tr>
                    <td style={{ textAlign: "center" }}>
                      {
                        data?.customer_brand_persona?.customer_brand?.brand
                          ?.name
                      }
                    </td>
                    {tableType == "reach" ? (
                      <td style={{ textAlign: "center" }}>
                        {Number(data?.avg_brand_reach_score).toFixed(0)}
                      </td>
                    ) : (
                      <>
                        <td style={{ textAlign: "center" }}>
                          {data?.metricType == "Brand Pillar"
                            ? `${data?.metricType} - ${data?.core_attribute?.name}`
                            : `${data?.metricType} - ${data?.category_dimension?.name}`}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {data?.metricType == "Brand Pillar"
                            ? Number(data?.avg_score).toFixed(0)
                            : Number(data?.avg_rating).toFixed(0)}
                        </td>
                      </>
                    )}

                    <td style={{ textAlign: "center" }}>
                      {data?.customer_llm?.llm?.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {data?.customer_brand_persona?.persona?.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {data?.customer_llm?.geography?.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {data?.customer_llm?.market?.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {data?.sources &&
                        data?.sources.length ?
                        data?.sources?.slice(0, 7)?.map((item) => {
                          return <p> {item} </p>;
                        }) : '--'}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
